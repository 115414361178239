import React from 'react';

import { Link } from '@reach/router';

import Container from './Container';
import Logo from './svg/Logo';
import Shipping from './svg/Shipping';
import { Box, NavLink, Text } from './system';

import theme from '../styles/theme';

const Footer = () => (
  <Box as="footer" bg={theme.colors.black} py={[5]} px={5}>
    <Container>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between" mx={[-4]}>
        <Box as="nav" display="flex" flexWrap="wrap">
          <Box width={[1, 'auto']} mx={[4]} my={[3, 0]}>
            <Text as="p" m={0} color="white" py={2} uppercase>
              Need Help?
            </Text>
            <NavLink display="table" color={theme.colors.gray} fontSize={[1]} to="/contact">
              Contact Us
            </NavLink>
            <NavLink display="table" color={theme.colors.gray} fontSize={[1]} to="/faq">
              FAQs
            </NavLink>
            <NavLink display="table" color={theme.colors.gray} fontSize={[1]} to="/shipping">
              Shipping
            </NavLink>
            <NavLink display="table" color={theme.colors.gray} fontSize={[1]} to="/returns-policy">
              Returns
            </NavLink>
          </Box>
          <Box width={[1, 'auto']} mx={[4]} my={[3, 0]}>
            <Text as="p" m={0} color="white" py={2} uppercase>
              Additional Info
            </Text>
            <NavLink display="table" color={theme.colors.gray} fontSize={[1]} to="/privacy">
              Privacy
            </NavLink>
            <NavLink
              display="table"
              color={theme.colors.gray}
              fontSize={[1]}
              to="/conditions-of-use"
            >
              Conditions of use
            </NavLink>
            <NavLink display="table" color={theme.colors.gray} fontSize={[1]} to="/about">
              About
            </NavLink>
          </Box>
          <Box width={[1, 'auto']} flex={1} mx={[4]} my={[3, 0]}>
            <Box width="3.5rem">
              <Shipping color={theme.colors.yellow} />
            </Box>
            <Text as="p" py={2} fontSize={1} color="white" fontWeight="bold">
              Free delivery on all deals!
            </Text>
          </Box>
        </Box>
        <Box
          width={[1, 1, 1, 1, 'auto']}
          mx={[4]}
          mt={[4, null, null, null, 0]}
          display="flex"
          flexWrap="wrap"
          flex={[null, null, null, null, 1]}
          alignSelf={[null, null, null, 'flex-end']}
          maxWidth={[null, null, null, null, '30rem']}
        >
          <Box width={[1, null, 1 / 2, 1 / 2, 1]} mb={4} pt={[0, 0, 0, 0, 2]}>
            <Text as="p" color="subduedgray" fontSize={1} mb={2}>
              Did I hear you say you didn't want to pay full price on sport &amp; fitness
              supplements?{' '}
              <Text fontWeight="bold" color="white">
                Fellow Kiwis, we got you!{' '}
              </Text>
              Supplement Deals are here to bring great discounts on popular supplements. Check back
              regularly for top deals on protein powder, bodybuilding supplements, fat burners,
              vitamins &amp; oils, creatine, endurance products, and more.
            </Text>
          </Box>
          <Box
            width={[1, null, 1 / 2, 1 / 2, 1]}
            display="flex"
            alignItems={['center', 'flex-end']}
            flexDirection="column"
          >
            <Box width={[1, '20rem', '16rem', '20rem']}>
              <Link aria-label="Supplement Deals Home" to="/">
                <Logo textColor="white" style={{ width: '100%' }} />
              </Link>
            </Box>
            <Box mt={0} alignSelf="flex-end" pr={1}>
              <Text as="p" color="nearwhite" fontSize={1}>
                Powered by{' '}
                <Text
                  as="a"
                  to="https://www.sportsfuel.co.nz"
                  target="_blank"
                  rel="noreferrer noopener"
                  color="yellow"
                  css={`
                    text-decoration: none;
                    &:hover {
                      text-decoration: underline;
                    }
                  `}
                >
                  Sportsfuel
                </Text>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  </Box>
);

export default Footer;
