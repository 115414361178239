import styled from 'styled-components';
import * as ss from 'styled-system';
import PropTypes from 'prop-types';

export const Image = styled.img`
  ${ss.color}
  ${ss.space}
  ${ss.width}
  ${ss.height}
  ${ss.flex}
  ${ss.display}
  ${ss.justifyContent}
  ${ss.opacity}
  ${ss.position}
  ${ss.top}
  ${ss.left}
  ${ss.bottom}
  ${ss.right}
  max-width: 100%;
  object-fit: ${({ fit = 'unset' }) => fit};
  font-family: ${({ fit }) =>
    fit === 'cover' || fit === 'contain' ? `object-fit: ${fit};` : 'inherit'};
`;

Image.propTypes = {
  fit: PropTypes.string
};
