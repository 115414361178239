import styled from 'styled-components';
import * as ss from 'styled-system';

export const TextArea = styled.textarea`
  ${ss.color}
  ${ss.display}
  ${ss.fontFamily}
  ${ss.fontSize}
  ${ss.fontStyle}
  ${ss.fontWeight}
  ${ss.lineHeight}
  ${ss.space}
  ${ss.textAlign}
  ${ss.boxShadow}
  ${ss.borders}
  ${ss.width}
`;

TextArea.defaultProps = {
  p: 2,
  fontSize: 2,
  border: 'formDefault',
  color: 'nearblack',
  rows: 7
};
