import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from '../system/Svg';

const Shipping = ({ color = 'white', ...props }) => (
  <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 612">
    <path
      fill={color}
      d="M504.9 395.8a52 52 0 1 0 .1 104.1 52 52 0 0 0-.1-104.1zm0 67.3a15.3 15.3 0 1 1 0-30.7 15.3 15.3 0 0 1 0 30.7z"
    />
    <path
      fill={color}
      d="M500 179.5h-90c-6.7 0-12.2 5.5-12.2 12.3v238.6c0 6.8 5.5 12.3 12.2 12.3h13c6 0 11.4-4.4 12.2-10.4 4.8-36.8 34.2-59.6 69.7-59.6s64.9 22.8 69.7 59.6c.8 6 6.1 10.4 12.2 10.4h13c6.7 0 12.2-5.5 12.2-12.3V311.1c0-2.9-1-5.7-2.9-7.9L509.3 184a12.2 12.2 0 0 0-9.4-4.4zm-77.7 98v-61.3c0-6.7 5.5-12.2 12.2-12.2h54c3.6 0 7 1.6 9.3 4.4l51 61.2c6.7 8 1 20-9.4 20H434.5c-6.7 0-12.2-5.4-12.2-12.2z"
    />
    <path
      fill={color}
      d="M12.2 442.7h31.4c6 0 11.4-4.4 12.2-10.4 4.8-36.8 34.2-59.6 69.7-59.6s64.8 22.8 69.6 59.6c.8 6 6.2 10.4 12.3 10.4H361c6.7 0 12.2-5.5 12.2-12.3v-306c0-6.7-5.5-12.2-12.2-12.2H12.2c-6.7 0-12.2 5.5-12.2 12.2v306c0 6.8 5.5 12.3 12.2 12.3z"
    />
    <path
      fill={color}
      d="M125.5 395.8a52 52 0 1 0 0 104.1 52 52 0 0 0 0-104.1zm0 67.3a15.3 15.3 0 1 1 0-30.7 15.3 15.3 0 0 1 0 30.7z"
    />
  </Svg>
);

Shipping.propTypes = {
  color: PropTypes.string
};

export default Shipping;
