import styled from 'styled-components';
import * as ss from 'styled-system';

export const Text = styled.span`
  ${ss.color}
  ${ss.display}
  ${ss.fontFamily}
  ${ss.fontSize}
  ${ss.fontStyle}
  ${ss.fontWeight}
  ${ss.lineHeight}
  ${ss.space}
  ${ss.textAlign}
  ${ss.boxShadow}
  text-transform: ${({ uppercase = false }) => (uppercase ? 'uppercase' : 'none')};
`;
