import { createGlobalStyle } from 'styled-components';

import reset from './reset';
import theme from './theme';

const Global = createGlobalStyle`
  ${reset}
  
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  html {
    font-size: 18px;
    line-height: 27px;
    color: ${theme.colors.black};
  }

  body {
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    font-size: 1em;
    line-height: 1.3em;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.black};
    line-height: 1.3em;
  }

  p {
    color: ${theme.colors.black};
    line-height: 1.5em;
    margin-bottom: 0.75rem;
  }

  strong {
    font-weight: bold;
  }

  /* remove blue outline */
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }

  /* remove autofill */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export default Global;
