import React from 'react';

import { Box } from './system';

import theme from '../styles/theme';

const Container = ({ maxWidth = null, ...props }) => (
  <Box maxWidth={maxWidth || theme.breakpoints[3]} mx="auto" bg="transparent" {...props} />
);

export default Container;
