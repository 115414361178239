import React from 'react';
import styled, { css } from 'styled-components';
import * as ss from 'styled-system';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

const styles = css`
  ${ss.opacity}
  ${ss.space}
  ${ss.fontSize}
  ${ss.fontWeight}
  ${ss.display}
  ${ss.color}
  text-decoration: none;
  text-transform: ${({ uppercase = false }) => (!uppercase ? 'capitalize' : 'uppercase')};

  &:hover {
    text-decoration: underline;
  }
`;

const StyledA = styled.a`
  ${styles}
`;

const StyledLink = styled.span`
  ${styles}
`;

const NavLink = ({ external = false, to, ...props }) =>
  external ? (
    <StyledA href={to} {...props} />
  ) : (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <StyledLink {...props} />
    </Link>
  );

const defaultProps = {
  py: 2
};

StyledA.defaultProps = defaultProps;
StyledLink.defaultProps = defaultProps;

NavLink.propTypes = {
  external: PropTypes.bool,
  uppercase: PropTypes.bool
};

export { NavLink };
