import React, { Fragment } from 'react';
import { Head, Root, Routes } from 'react-static';
import { ThemeProvider } from 'styled-components';
import objectFitImages from 'object-fit-images';

import theme from './styles/theme';
import GlobalStyles from './styles/global';

import Footer from './components/Footer';

if (typeof window !== 'undefined') {
  require('lazysizes/plugins/attrchange/ls.attrchange.js');
  require('lazysizes');
}

objectFitImages();

function App() {
  return (
    <Root>
      <ThemeProvider theme={theme}>
        <Fragment>
          <Head titleTemplate="%s | Supplement Deals NZ" />
          <GlobalStyles />

          <div className="content">
            <Routes />
          </div>
          <Footer />
        </Fragment>
      </ThemeProvider>
    </Root>
  );
}

export default App;
