// Enter default brand colour into generator at https://palx.jxnblk.com to find colours

const yellow = '#ffd500';
const subduedyellow = '#ffde39';
const darkyellow = '#e6c000';
const lightyellow = '#ffed95';
//
const gray = '#b6b3a2';
const subduedgray = '#c6c4b6';
const darkgray = '#706c58';
const lightgray = '#e2e0da';
const xlightgray = '#eeede9';
//
const green = '#00cb43';
//
const sportsfuelcharcoal = '#4f4f4f';
const sportsfuelorange = '#fd6603';
//
const black = '#222019';
const nearblack = '#2d2b23';
//
const white = '#fff';
const nearwhite = '#faf9f8';
//
const transparent = 'transparent';

export default {
  borders: {
    lightyellow: `4px solid ${lightyellow}`,
    nearwhite: `4px solid ${nearwhite}`,
    formDefault: `2px solid ${nearwhite}`,
    formError: `2px solid ${yellow}`
  },
  breakpoints: ['40em', '52em', '64em', '76rem'],
  fontSizes: ['0.64em', '0.8em', '1em', '1.25em', '1.563em', '1.953em', '2.441em', '3.052em'],
  colors: {
    yellow,
    subduedyellow,
    darkyellow,
    lightyellow,
    //
    gray,
    subduedgray,
    darkgray,
    lightgray,
    xlightgray,
    //
    green,
    //
    sportsfuelcharcoal,
    sportsfuelorange,
    //
    black,
    nearblack,
    //
    white,
    nearwhite,
    //
    transparent
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: 'sans-serif'
  },
  shadows: {
    sm: '0 2px 8px rgba(0, 0, 0, .125)',
    md: '0 2px 18px rgba(0, 0, 0, .125)',
    lg: '0 2px 24px rgba(0, 0, 0, .15)'
  },
  transitions: { xs: '0.15s', sm: '0.2s', md: '0.3s', lg: '0.7s' },
  maxWidths: { sm: '25rem', md: '40rem', lg: '60rem' }
};
