import styled from 'styled-components';
import * as ss from 'styled-system';

const Box = styled.div`
  ${ss.color}
  ${ss.space}
  ${ss.borders}
  ${ss.borderColor}
  ${ss.borderStyle}
  ${ss.borderWidth}
  ${ss.borderRadius}
  ${ss.width}
  ${ss.height}
  ${ss.flex}
  ${ss.flexDirection}
  ${ss.flexWrap}
  ${ss.display}
  ${ss.justifyContent}
  ${ss.alignItems}
  ${ss.alignSelf}
  ${ss.opacity}
  ${ss.position}
  ${ss.left}
  ${ss.top}
  ${ss.bottom}
  ${ss.right}
  ${ss.zIndex}
  ${ss.boxShadow}
  ${ss.maxWidth}
  ${ss.minWidth}
  ${ss.maxHeight}
  ${ss.minHeight}
  ${ss.textAlign}
`;

export { Box };
