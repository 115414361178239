import React from 'react';
import PropTypes from 'prop-types';

import { Svg } from '../system/Svg';

import theme from '../../styles/theme';

const Logo = ({ iconColor, textColor, ...props }) => (
  <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2964.9 544.9">
    <g fill={textColor || theme.colors.black}>
      <path d="M746.3 301A67.8 67.8 0 0 1 725 255c0-30.2 18.4-53.6 72.2-53.6 49 0 53.8 16.4 53.8 27.2 0 7.4-3.5 16.5-9.2 23a78.3 78.3 0 0 0-38-9.1c-14 0-21 4.7-21 13.4 0 4.8 1.2 10 13 21.6l32.4 32.5c17.5 19.9 21.9 32.4 21.9 54 0 39-28.4 62.3-73 62.3-28.5 0-51.7-6-51.7-25.5a58 58 0 0 1 8-26.8 83 83 0 0 0 38.4 9.5c15.3 0 22.7-7.4 22.7-18.6 0-6-1.3-14.3-10-24.2l-38-39.8z" />
      <path d="M1000 410c-14 8.2-38.5 16.4-64.7 16.4-52.5 0-66-24.2-66-65.3V214.5c0-8.7 0-8.7 50.2-8.7v158.3c0 14.3 2.6 21.2 15.3 21.2 5.7 0 11.8-1.3 16.6-5.6V214.5c0-8.7-.4-8.7 48.6-8.7V410z" />
      <path d="M1028 218.4c17-10.4 41.5-16.9 64.7-16.9 41.5 0 70.4 16.9 70.4 87.8 0 116-25.8 137.1-57.3 137.1-13.1 0-22.3-2.6-29.3-8.2v85.2a79 79 0 0 1-25 3.9c-17 0-23.5-4.3-23.5-21.2V218.4zm48.5 157.4c4.4 6 7.9 8.2 12.7 8.2 9.6 0 19.7-9 19.7-91.7 0-42-5.7-49.7-18.8-49.7a23 23 0 0 0-13.6 5.2v128z" />
      <path d="M1186.7 218.4c17-10.4 41.6-16.9 64.7-16.9 41.6 0 70.4 16.9 70.4 87.8 0 116-25.8 137.1-57.2 137.1-13.2 0-22.3-2.6-29.3-8.2v85.2a79 79 0 0 1-25 3.9c-17 0-23.6-4.3-23.6-21.2V218.4zm48.6 157.4c4.3 6 7.8 8.2 12.6 8.2 9.7 0 19.7-9 19.7-91.7 0-42-5.7-49.7-18.8-49.7a23 23 0 0 0-13.5 5.2v128z" />
      <path d="M1394 413.4c0 8.7 0 8.7-48.5 8.7V125.4c0-21.2 9.1-21.2 48.5-21.2v309.2z" />
      <path d="M1543.6 367.6c2.6 8.6 4 22 4 32 0 17.3-28 26.8-62.2 26.8-39.8 0-67.8-19.9-67.8-102 0-104.7 36.3-122.9 77-122.9 35 0 56 20.3 56 70.5 0 20.8-1.8 44.5-6.2 61.4a497 497 0 0 1-76 6c1.7 28.2 7.8 40.3 28.8 40.3 15.8 0 31-4.8 46.4-12.1zm-75.2-65.8c12.2 0 26.2-.8 32.8-3.8a89.3 89.3 0 0 0 2.1-22.5c0-27.7-3.9-32.5-14.8-32.5-13.6 0-18.8 19-20.1 58.8z" />
      <path d="M1574.2 215.8c17-7.8 41-14.3 67.3-14.3 16.2 0 28 3.9 36.8 11.2 15.3-6.9 34.5-11.2 51.6-11.2 44.6 0 49.4 21.6 49.4 58.8v153.1c0 8.7-1.8 8.7-48.6 8.7V261.6c0-13.8-2.6-18.1-13-18.1-6.2 0-11.9 1.3-16.7 3.8V414c0 7.7.4 8.2-39.4 8.2h-9.1V259.5c0-14.3-2.2-17-12.7-17-5.7 0-11.8 2.3-17 5.3v165.6c0 8.7-1.4 8.7-48.6 8.7V215.8z" />
      <path d="M1931 367.6c2.7 8.6 4 22 4 32 0 17.3-28 26.8-62.1 26.8-39.8 0-67.8-19.9-67.8-102 0-104.7 36.3-122.9 77-122.9 35 0 56 20.3 56 70.5 0 20.8-1.8 44.5-6.2 61.4a497 497 0 0 1-76 6c1.7 28.2 7.8 40.3 28.8 40.3 15.7 0 31-4.8 46.3-12.1zm-75.2-65.8c12.3 0 26.3-.8 32.8-3.8a89.3 89.3 0 0 0 2.2-22.5c0-27.7-4-32.5-14.9-32.5-13.5 0-18.8 19-20 58.8z" />
      <path d="M1961.6 215.8c14-6.5 46-14.3 67.8-14.3 44.6 0 63 12.5 63 55.8v156.1c0 8.7-1.3 8.7-50.3 8.7V272.4c0-22.9-2.2-29.8-14.4-29.8-6.1 0-12.3 1.7-17.5 5.2v165.6c0 8.7-.9 8.7-48.6 8.7V215.8z" />
      <path d="M2109.5 246.5c0-36.4.4-40.7 10-40.7h16.6v-62.7c0-15.1 9.2-15.1 48.6-15.1v77.8h35.8c0 36.8-.4 40.7-9.6 40.7h-26.2v118c0 10 0 21.2 10.5 21.2 7.9 0 14.4-1.7 22.7-5.6 1.3 6 3 13.4 3 23 0 20.7-23 23.3-39.7 23.3-38.5 0-45-22.5-45-61v-119h-26.7z" />
      <path d="M2386.3 125.4c0-21.2 8.3-21.2 48.5-21.2v309.2a155 155 0 0 1-63.8 13c-37.2 0-70.9-10.8-70.9-102.5 0-88.7 26.3-122.4 60.4-122.4 11.8 0 20.5 3.5 25.8 7.8v-84zm0 119.4a17.6 17.6 0 0 0-9.6-2.2c-8.8 0-21.9 3.9-21.9 75.2 0 52 5.3 66.2 16.6 66.2 6.1 0 11-1.3 14.9-4.3v-135z" />
      <path d="M2583 367.6c2.7 8.6 4 22 4 32 0 17.3-28 26.8-62 26.8-39.9 0-67.9-19.9-67.9-102 0-104.7 36.3-122.9 77-122.9 35 0 56 20.3 56 70.5 0 20.8-1.8 44.5-6.1 61.4a497 497 0 0 1-76.1 6c1.7 28.2 7.8 40.3 28.8 40.3 15.8 0 31-4.8 46.4-12.1zm-75.1-65.8c12.2 0 26.2-.8 32.8-3.8a89.3 89.3 0 0 0 2.2-22.5c0-27.7-4-32.5-15-32.5-13.5 0-18.7 19-20 58.8z" />
      <path d="M2735.3 274.6c0 49.7 0 106-2.2 138-14.9 8.6-36.7 13.8-63 13.8-32.4 0-62-7.3-62-67.5 0-59.2 24.8-71.3 50.6-71.3 6.6 0 20.2 1.3 28 6.5v-29.9c0-11.7-4.8-16.4-18.3-16.4-16.7 0-38.5 3.9-53 9a61.3 61.3 0 0 1-6.5-27.6c0-5.2 2.2-12.1 6.1-15.2 6.1-4.7 30.2-12.5 65.2-12.5 40.2 0 55 13.8 55 52.8v20.3zm-60 48c-10 0-16.1 7-16.1 35 0 21.2 3.5 29.4 14.4 29.4 4.4 0 9.6-1.3 12.3-4.3.8-10 .8-38 .8-56.2a19 19 0 0 0-11.3-3.9z" />
      <path d="M2810.5 413.4c0 8.7 0 8.7-48.6 8.7V125.4c0-21.2 9.2-21.2 48.6-21.2v309.2z" />
      <path d="M2854.7 301a67.8 67.8 0 0 1-21.5-45.9c0-30.2 18.4-53.6 72.2-53.6 49 0 53.8 16.4 53.8 27.2 0 7.4-3.5 16.5-9.2 23a78.3 78.3 0 0 0-38-9.1c-14 0-21 4.7-21 13.4 0 4.8 1.3 10 13 21.6l32.4 32.5c17.5 19.9 21.9 32.4 21.9 54 0 39-28.4 62.3-73 62.3-28.5 0-51.6-6-51.6-25.5a58 58 0 0 1 7.8-26.8 83 83 0 0 0 38.5 9.5c15.3 0 22.8-7.4 22.8-18.6 0-6-1.3-14.3-10.1-24.2l-38-39.8z" />
    </g>
    <path
      fill={iconColor || theme.colors.yellow}
      d="M546 211H439.6v-70.4a35.2 35.2 0 0 0-60-24.8L238.7 256.4a35.1 35.1 0 0 0 0 49.7l80.6 80.6-98.2 98.2a35.1 35.1 0 1 0 49.8 49.7l123-123a35.1 35.1 0 0 0 0-49.7l-80.6-80.6 55.7-55.8V246a35.2 35.2 0 0 0 35.2 35.1h141.8a35.2 35.2 0 0 0 0-70.3z"
    />
    <path
      fill={iconColor || theme.colors.yellow}
      d="M309.5 32.3a35.2 35.2 0 0 0-44.3 4.4l-96.7 96.6a35.1 35.1 0 1 0 49.7 49.8l76.3-76.3 25.7 18.5 34.4-34.4a69.9 69.9 0 0 1 19.9-13.7l-65-45z"
    />
    <path
      fill={iconColor || theme.colors.yellow}
      d="M214 331c-9-9-14.9-20-18-31.8L10.3 485A35.1 35.1 0 1 0 60 534.6l178.8-178.8L214 331z"
    />
    <circle fill={iconColor || theme.colors.yellow} cx="457" cy="52.7" r="52.7" />
  </Svg>
);

Logo.propTypes = {
  iconColor: PropTypes.string,
  textColor: PropTypes.string
};

export default Logo;
