import styled from 'styled-components';
import * as ss from 'styled-system';
import PropTypes from 'prop-types';

const ButtonLink = styled.a`
  ${ss.color}
  ${ss.space}
  ${ss.borders}
  ${ss.borderColor}
  ${ss.borderRadius}
  ${ss.width}
  ${ss.height}
  ${ss.flex}
  ${ss.display}
  ${ss.alignSelf}
  ${ss.opacity}
  ${ss.position}
  ${ss.left}
  ${ss.top}
  ${ss.bottom}
  ${ss.right}
  ${ss.zIndex}
  ${ss.maxWidth}
  ${ss.minWidth}
  ${ss.maxHeight}
  ${ss.minHeight}
  ${ss.textAlign}
  ${ss.fontSize}
  ${ss.boxShadow}
  ${ss.fontWeight}
  text-transform: ${({ uppercase = true }) => (uppercase ? 'uppercase' : 'capitalize')};
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.sm} ease;
  text-decoration: none;

  &:disabled {
    background: ${({ theme }) => theme.colors.lightgray};
    border-color: ${({ theme }) => theme.colors.lightgray};
    color: ${({ theme }) => theme.colors.darkgray};
  }

  &:disabled:hover {
    opacity: 1;
  }
`;

ButtonLink.defaultProps = {
  boxShadow: 'md',
  py: 3,
  px: 5,
  bg: 'subduedyellow',
  borderColor: 'subduedyellow',
  color: 'nearblack',
  borderRadius: '2.5rem',
  fontSize: 2,
  textAlign: 'center',
  fontWeight: 'bold'
};

ButtonLink.propTypes = {
  asLink: PropTypes.bool,
  uppercase: PropTypes.bool,
  boxShadow: PropTypes.string,
  py: PropTypes.number,
  px: PropTypes.number,
  bg: PropTypes.string,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontSize: PropTypes.number,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string
};

export { ButtonLink };
